@charset "UTF-8";
/********  Colors  ********/
.compare_methods {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 90vw; }
  .compare_methods .rainbow {
    display: none; }
  .compare_methods .nav-buttons-container {
    flex-direction: row; }
    .compare_methods .nav-buttons-container .nav-button {
      line-height: 38px; }
    .compare_methods .nav-buttons-container .regular.fake-ribbon {
      width: auto;
      padding: 0 20px;
      justify-self: flex-start;
      font-size: 20px; }
  .compare_methods .mobile, .compare_methods .fake {
    display: none; }
  .compare_methods .markdown-block p {
    margin-bottom: 20px; }
  .compare_methods .overlay .modal {
    min-width: 650px;
    width: 92vw;
    max-width: 708px;
    padding: 20px; }
    .compare_methods .overlay .modal .heading-container {
      padding-top: 0px; }
      .compare_methods .overlay .modal .heading-container h1 {
        color: #a2c800;
        font-size: 24px; }
    .compare_methods .overlay .modal .modal-content {
      max-height: calc(100vh - 54px);
      overflow-y: auto; }
      .compare_methods .overlay .modal .modal-content .rec-section-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center; }
        .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          width: 114px;
          margin-left: calc((90vw - 70px - 456px) / 8);
          margin-right: calc((90vw - 70px - 456px) / 8);
          margin-bottom: 10px; }
          .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container p, .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container label {
            font-size: 16px;
            line-height: 19px;
            white-space: pre-wrap;
            margin-bottom: 0;
            text-align: center; }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container p .stars-container, .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container label .stars-container {
              display: -webkit-flex;
              display: flex;
              margin-top: 5px;
              -webkit-justify-content: center;
              justify-content: center; }
              .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container p .stars-container .star, .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container label .stars-container .star {
                margin: 0 2.5px; }
          .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon {
            height: 97px;
            width: 90px;
            padding-bottom: 7px;
            background-color: white;
            background-size: contain;
            background-repeat: no-repeat; }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.sterilization {
              background-image: url("../../../shared_images/methods/sterilization@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.hormonalIud {
              background-image: url("../../../shared_images/methods/hormonal-iud@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.copperIud {
              background-image: url("../../../shared_images/methods/copper-iud@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.implant {
              background-image: url("../../../shared_images/methods/implant@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.shot {
              background-image: url("../../../shared_images/methods/shot@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.ring {
              background-image: url("../../../shared_images/methods/ring@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.patch {
              background-image: url("../../../shared_images/methods/patch@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.pill {
              background-image: url("../../../shared_images/methods/pill@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.diaphragm {
              background-image: url("../../../shared_images/methods/diaphragm@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.maleCondom {
              background-image: url("../../../shared_images/methods/male-condom@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.femaleCondom {
              background-image: url("../../../shared_images/methods/internal-condom@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.cervicalCap {
              background-image: url("../../../shared_images/methods/cervical-cap@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.fertilityAwareness {
              background-image: url("../../../shared_images/methods/fertility-awareness@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.sponge {
              background-image: url("../../../shared_images/methods/sponge@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.spermicide {
              background-image: url("../../../shared_images/methods/spermicide@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.withdrawal {
              background-image: url("../../../shared_images/methods/withdrawal@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.emergencyContraception {
              background-image: url("../../../shared_images/methods/emergency-contraception@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.other {
              background-image: url("../../../shared_images/methods/other@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.notRightNow {
              background-image: url("../../../shared_images/methods/not-right-now@2x.jpg"); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.notUsingAnyMethod {
              background-image: url("../../../shared_images/methods/no-method@2x.jpg");
              background-position: center center; }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon.progestinOnlyPill {
              background-image: url("/shared_images/methods/progestin_only_pill@2x.jpg"); }
      .compare_methods .overlay .modal .modal-content .star-legend-container {
        background-color: #efefef;
        border: 1px dotted #808080;
        padding: 13px 13px 3px 13px;
        margin-top: 5px;
        font-size: 13px; }
        .compare_methods .overlay .modal .modal-content .star-legend-container .star-legend-heading {
          font-weight: bold;
          font-style: italic;
          margin-bottom: 13px;
          line-height: 1.2; }
        .compare_methods .overlay .modal .modal-content .star-legend-container .list-item {
          display: -webkit-flex;
          display: flex; }
          .compare_methods .overlay .modal .modal-content .star-legend-container .list-item:nth-of-type(1):before {
            color: #005566; }
          .compare_methods .overlay .modal .modal-content .star-legend-container .list-item:nth-of-type(2):before {
            color: #a2c800; }
          .compare_methods .overlay .modal .modal-content .star-legend-container .list-item:nth-of-type(3):before {
            color: #f37928; }
          .compare_methods .overlay .modal .modal-content .star-legend-container .list-item:before {
            content: "★";
            font-size: 18.2px;
            line-height: 13px;
            margin-right: 2px; }
          .compare_methods .overlay .modal .modal-content .star-legend-container .list-item p {
            font-size: 13px;
            margin-bottom: 10px;
            line-height: 16px; }
  .compare_methods .compare-table {
    margin-bottom: 20px;
    align-self: center;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 100%;
    width: calc(100% + 22px);
    font-style: italic;
    border-collapse: separate;
    border-spacing: 11px 0; }
    .compare_methods .compare-table .table-row, .compare_methods .compare-table .table-td.active, .compare_methods .compare-table .table-tab {
      border-bottom: 1px dashed #808080; }
    .compare_methods .compare-table .table-row, .compare_methods .compare-table #blank, .compare_methods .compare-table .table-td {
      font-size: 13px;
      line-height: 18px; }
    .compare_methods .compare-table .table-row, .compare_methods .compare-table .table-td {
      padding-top: 11px;
      padding-bottom: 11px;
      box-sizing: border-box; }
    .compare_methods .compare-table .table-td, .compare_methods .compare-table .table-tab:nth-of-type(2), .compare_methods .compare-table .table-tab:nth-of-type(3) {
      border-left: 1px dotted #808080;
      border-right: 1px dotted #808080; }
    .compare_methods .compare-table .table-row {
      white-space: pre-wrap;
      text-align: right;
      height: 101px; }
      .compare_methods .compare-table .table-row#benefits, .compare_methods .compare-table .table-row#who-shouldn\'t-use-this-method {
        height: 121px; }
    .compare_methods .compare-table #blank {
      color: transparent;
      text-align: right; }
    .compare_methods .compare-table .table-td {
      background-color: #efefef;
      box-sizing: border-box;
      width: 39%;
      height: 101px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative; }
      .compare_methods .compare-table .table-td ul {
        list-style: disc outside none;
        margin-left: 27px; }
        .compare_methods .compare-table .table-td ul li {
          padding-left: 9px; }
    .compare_methods .compare-table .table-tab {
      padding: 0 9px 0 5px;
      text-align: left;
      vertical-align: middle;
      position: relative;
      height: 60px; }
      .compare_methods .compare-table .table-tab:nth-of-type(2), .compare_methods .compare-table .table-tab:nth-of-type(3) {
        border-top: 1px dotted #808080; }
      .compare_methods .compare-table .table-tab.inactive {
        background-color: #efefef; }
      .compare_methods .compare-table .table-tab.active {
        background-color: #005566;
        border-bottom: 1px solid #808080; }
      .compare_methods .compare-table .table-tab .active-tab-button {
        background-image: url("./thick-close-icon@2x.png");
        background-color: #005566;
        background-position: right 2px center;
        background-repeat: no-repeat;
        background-size: auto 16px;
        width: 100%; }
        .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          color: white;
          font-size: 16px;
          font-style: italic;
          padding-left: 40px;
          background-size: 40px;
          line-height: 16px;
          background-repeat: no-repeat;
          max-width: 65%;
          min-height: 40px;
          vertical-align: middle; }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.femaleCondom {
            background-image: url("../../../shared_images/illustrated_method_icons/internal-condom_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.maleCondom {
            background-image: url("../../../shared_images/illustrated_method_icons/male-condom_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.diaphragm {
            background-image: url("../../../shared_images/illustrated_method_icons/diaphragm_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.pill {
            background-image: url("../../../shared_images/illustrated_method_icons/pill_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.patch {
            background-image: url("../../../shared_images/illustrated_method_icons/patch_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.ring {
            background-image: url("../../../shared_images/illustrated_method_icons/ring_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.shot {
            background-image: url("../../../shared_images/illustrated_method_icons/shot_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.implant {
            background-image: url("../../../shared_images/illustrated_method_icons/implant_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.hormonalIud, .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.copperIud {
            background-image: url("../../../shared_images/illustrated_method_icons/iud_blue.jpg"); }
          .compare_methods .compare-table .table-tab .active-tab-button .active-tab-paragraph.sterilization {
            background-image: url("../../../shared_images/illustrated_method_icons/sterilization_blue.jpg"); }
      .compare_methods .compare-table .table-tab .close-x {
        height: 0;
        font-size: 0;
        padding: 8px;
        float: right;
        position: absolute;
        top: 50%;
        right: 11px;
        transform: translateY(-8px);
        background-image: url("./thick-close-icon@2x.png");
        background-color: transparent;
        background-size: contain; }
      .compare_methods .compare-table .table-tab .select-button {
        color: #005566;
        font-style: italic;
        font-size: 18px;
        line-height: 16px;
        background-color: #efefef;
        padding: 5px; }
    .compare_methods .compare-table tr:last-of-type .table-td {
      border-bottom: 1px dotted #808080; }
    .compare_methods .compare-table tr:last-of-type .table-row {
      border-bottom: none; }

@media only screen and (min-width: 769px) {
  .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container {
    margin-left: 21px;
    margin-right: 21px; } }

@media only screen and (min-width: 835px) {
  .compare_methods .overlay .modal .modal-content .heading-container h1 {
    margin-top: 0; }
  .compare_methods .compare-table .table-row, .compare_methods .compare-table .table-td {
    height: 71px; }
  .compare_methods .compare-table .table-tab {
    height: 60px; } }

@media only screen and (max-width: 719px) {
  .compare_methods .heading-container h1 {
    font-size: 3.5vw; }
  .compare_methods .ribbon.regular {
    top: 25px; }
  .compare_methods .overlay .modal {
    min-width: 0; }
  .compare_methods .compare-table.regular .table-tab .select-button {
    font-size: 2.5vw; } }

@media only screen and (max-width: 599px) {
  .compare_methods .regular {
    display: none; }
  .compare_methods .mobile {
    display: block; }
  .compare_methods .heading-container {
    padding: 15px 0 12px; }
    .compare_methods .heading-container h1 {
      font-size: 18px;
      padding: 4px 0;
      white-space: pre-wrap; }
  .compare_methods .markdown-block p {
    font-size: 16px;
    line-height: 1.5; }
  .compare_methods .overlay {
    background-color: white; }
    .compare_methods .overlay .modal {
      border-radius: 0;
      width: 100vw;
      height: 100%;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0; }
      .compare_methods .overlay .modal .rainbow {
        margin-bottom: 16px;
        display: block; }
      .compare_methods .overlay .modal .close-x {
        display: none; }
      .compare_methods .overlay .modal .modal-content {
        padding: 0 5vw;
        overflow-x: hidden;
        max-height: none;
        background-color: white; }
        .compare_methods .overlay .modal .modal-content .heading-container {
          padding-bottom: 2px; }
          .compare_methods .overlay .modal .modal-content .heading-container h1 {
            color: #005566;
            font-size: 18px; }
        .compare_methods .overlay .modal .modal-content .nav-buttons-container.fake {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          padding: 20px 0 30px 0; }
        .compare_methods .overlay .modal .modal-content .rec-section-container {
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
          margin-left: calc((90vw - 3 * 90px ) / (-4));
          margin-right: calc((90vw - 3 * 90px ) / (-4)); }
          .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container {
            width: 90px;
            margin-left: calc((90vw - 3 * 90px ) / (4));
            margin-right: calc((90vw - 3 * 90px ) / (4)); }
            .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container p {
              font-size: 13px; }
  .compare_methods .compare-table {
    width: 100%;
    border-collapse: collapse; }
    .compare_methods .compare-table tr.inactive {
      display: none; }
    .compare_methods .compare-table tr:last-of-type .table-td {
      border-bottom: 0px; }
    .compare_methods .compare-table .table-tab {
      border: 1px dotted #808080;
      padding: 0 4px;
      height: 50px;
      width: 50vw; }
      .compare_methods .compare-table .table-tab.active .active-tab-paragraph {
        background-image: none;
        padding-left: 36px; }
      .compare_methods .compare-table .table-tab .select-button {
        font-size: 16px;
        top: 0; }
    .compare_methods .compare-table .table-row {
      background-color: #005566;
      color: white;
      white-space: normal;
      text-align: center;
      padding: 6px 0;
      height: auto;
      border-right: 1px solid #005566;
      border-bottom: 0; }
      .compare_methods .compare-table .table-row#benefits, .compare_methods .compare-table .table-row#who-shouldn\'t-use-this-method {
        height: auto; }
    .compare_methods .compare-table .table-td {
      height: 66px;
      background-color: transparent;
      border: 0px; }
      .compare_methods .compare-table .table-td.active {
        border: 0px; }
      .compare_methods .compare-table .table-td:last-of-type {
        border-left: 1px dotted #808080; }
      .compare_methods .compare-table .table-td ul li {
        padding-left: 0; }
  .compare_methods .nav-buttons-container .fake-ribbon {
    width: 100%;
    font-size: 20px;
    z-index: auto; }
  .compare_methods.es .nav-buttons-container .nav-button.fake-ribbon {
    font-size: 16px;
    line-height: 40px; }
  .compare_methods.es .compare-table.mobile .table-td ul {
    margin-left: 15px; }
    .compare_methods.es .compare-table.mobile .table-td ul li {
      padding-left: 0; } }

@media only screen and (max-width: 360px) {
  .compare_methods .overlay .modal .modal-content .rec-section-container {
    margin-left: calc((90vw - 3 * 80px ) / (-4));
    margin-right: calc((90vw - 3 * 80px ) / (-4)); }
    .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container {
      width: 80px;
      margin-left: calc((90vw - 3 * 80px ) / (4));
      margin-right: calc((90vw - 3 * 80px ) / (4));
      margin-bottom: 5px; }
      .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container .icon {
        width: 80px; }
      .compare_methods .overlay .modal .modal-content .rec-section-container .rec-icon-container p {
        height: 58px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between; } }
