@import '../../../scss/colors';
@import '../../../scss/mixins';

#fertility-by-age {
  .pie-graph {
    height: 452px;
    margin: 50px auto;
    position: relative;
    width: 452px;

    svg { transform: rotate(-90deg); }
    circle { stroke: black; }
    .circle-graph { fill: transparent; }
    .outer-circle { fill: white; }
    .inner-circle { fill: white; }
  }

  .caption {
  	height: 147px;
  	width: 450px;
  	color: #313131;
  	font-family: Georgia;
  	font-size: 20px;
  	font-style: italic;
    left: 20%;
  	line-height: 30px;
    position: absolute;
  	text-align: center;
    top: 33%;
    width: 60%;
    font-weight: normal;

    .chance {
      font-size: 48px;
      line-height: 60px;
    }
  }

  .button-bar {
    border: none;
    margin: 40px auto;
    padding: 0;
    border: 1px solid $x_light_gray;

    button {
      @include archer;
      border: none;
      border-radius: 0;
      color: $dark_gray;
      font-size: 18px;
      font-style: normal;
      height: 40px;
      line-height: 36px;
      width: 25%;
      border-right: 1px dotted #fff;
      background-color: $light_gray;

      &.active {
        background-image: url('../../../shared_images/narrow-hatching.png');
        &.dark_blue {
          background-color: $dark_blue; 
          color: white;
        }
        &.light_blue { background-color: $light_blue; }
        &.light_green { background-color: $light_green; }
        &.orange { background-color: $orange; }
        &.dark_orange { background-color: $dark_orange; }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .dark_blue {
    .chance { color: black; }
    circle.circle-graph { stroke: $dark_blue; }
  }
  .light_blue {
    .chance { color: black; }
    circle.circle-graph { stroke: $light_blue; }
  }
  .light_green {
    .chance { color: black; }
    circle.circle-graph { stroke: $light_green; }
  }
  .orange {
    .chance { color: black; }
    circle.circle-graph { stroke: $orange; }
  }
  .dark_orange {
    .chance { color: black; }
    circle { stroke: $dark_orange; }
  }
}

@media screen and (max-width: 490px) {
  #fertility-by-age {
    .pie-graph {
      height: 280px;
      width: 280px;
    }

    .caption {
    	height: 147px;
    	width: 300px;
    	font-size: 14px;
    	font-style: italic;
      left: 18%;
    	line-height: 1.5;
      position: absolute;
    	text-align: center;
      top: 33%;
      width: 64%;

      .chance {
        font-size: 24px;
        line-height: 40px;
      }
    }

    .button-bar button { font-size: 16px; }
  }
}
