@import '../../../scss/mixins';

$heading-line-height: 35px;
$heading-pad-top: 20px;
$heading-pad-btm: 16px;
$heading-total-height: 2 * $heading-line-height + $heading-pad-btm + $heading-pad-top;
$nav-container-total-height: 70px;
$rainbow-height: 15px;
$rainbow-heading-and-nav-total-height: $nav-container-total-height + $heading-total-height + $rainbow-height;
$image-height: 482px;
$uncropped-image-height: 409px;
$image-width: 360px;
$vertical-buffer: 8vw;

.pregnant_again_first.peripartum, .pregnant_again_second.peripartum, .pregnant_again_first.superDev, .pregnant_again_second.superDev {
  display: block;
  padding: 0;
  // background-color: red;
  
  .heading-container {
    padding: $heading-pad-top 0 $heading-pad-btm;

    h1{ 
      white-space: pre-wrap;
    }
  }

  .heading-container, .markdown-block, .nav-buttons-container { 
    padding-left: 5vw;
    padding-right: 5vw; 
  }

  .flex-group {
    display: block;

    .image-space {
      width: $image-width + 5px;
      height: calc(100vh - #{$rainbow-heading-and-nav-total-height});
      shape-outside: inset(calc(100vh - #{$rainbow-heading-and-nav-total-height} - #{$image-height}) 0 0 5px);
      float: right;
      background-image: url('./stroller.png');
      background-size: $image-width $image-height;
      background-position: right bottom;
      background-repeat: no-repeat;
      min-height: 490px;
    }
  }

  .spacer { 
    width: 100%;
    height: $vertical-buffer;
  }

  &.safari.not-standalone .flex-group .image-space { 
    height: calc(100vh - #{$rainbow-heading-and-nav-total-height} - 30px);
    shape-outside: inset(calc(100vh - #{$rainbow-heading-and-nav-total-height} - #{$image-height} - 30px) 0 0 5px);
  }
}

@media only screen and (max-width: 719px){
  .pregnant_again_first.peripartum, .pregnant_again_second.peripartum, .pregnant_again_first.superDev, .pregnant_again_second.superDev {
    display: flex;

    .heading-container {
      padding-top: 10px;
      padding-bottom: 7px;

      h1{ 
        line-height: 1.5;
        white-space: initial;
        
      }
    }
    
    .flex-group {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;

      .regular { display: none; }

      .spacer { display: none}
    }
  }
}
  
@media only screen and (min-width: 1000px){
  .pregnant_again_first.peripartum, .pregnant_again_second.peripartum, .pregnant_again_first.superDev, .pregnant_again_second.superDev {
    display: flex; 

    .heading-container {
      padding: 20px 0 4px; 
      align-self: center;

      h1{ white-space: initial;}
    }

    .heading-container, .flex-group, .nav-buttons-container { max-width: 885px; }

    .flex-group {
      .image-space { 
        $first-two-paragraphs-height: 40px; 

        background-image: url('./mom+stroller-FINAL@2x.png'); 
        background-size: $image-width $uncropped-image-height; 
        height: 660px - $first-two-paragraphs-height ; 
        shape-outside: inset(183px - $first-two-paragraphs-height 0 0 5px);
      }

      .markdown-block { padding: 0; }
    }
    .nav-buttons-container { padding: 0 0 30px}
  }
}

@media only screen and (max-height: 915px) and (max-width: 999px){
  .pregnant_again_first.peripartum, .pregnant_again_second.peripartum, .pregnant_again_first.superDev, .pregnant_again_second.superDev {
    .flex-group .image-space { 
      height: 726px; 
      shape-outside: inset(183px 0 0 5px);
    }
  }
}

@media only screen and (min-width: 1000px){
  .pregnant_again_first.peripartum, .pregnant_again_second.peripartum, .pregnant_again_first.superDev, .pregnant_again_second.superDev {
    .heading-container, .markdown-block, .nav-buttons-container { 
      padding-left: 0;
      padding-right: 0; 
    }
  }
}