@import '../../../scss/mixins';
@import '../../../scss/colors';

.iud_info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  .flex-group {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;

    .iud-info-image {
      height: 663px;
      width: 227px;

      // NOTE: There are issues with this image rendering at the wrong width
      // in chrome and setting the min-width seems to resolve that issue.
      min-width: 227px;
    }
    .markdown-block {
      margin-right: 0px;
      padding: 0 0 10px 0;

      ul {
        list-style-type: disc;
        margin: 0;

        li { margin-bottom: 10px; }
      }
    }
  }
}

@media only screen and (min-width: 835px){
  .iud_info {
    .heading-container { padding-top: 19px }

    .flex-group {

      .iud-info-image {
        height: 562px;
        width: 193px;

        // NOTE: There are issues with this image rendering at the wrong width
        // in chrome and setting the min-width seems to resolve that issue.
        min-width: 193px;
      }

      .markdown-block {
        padding-bottom: 22px;

        p {margin-bottom: 19px;}

        ul {  padding: 0px 0 10px 38px;  }
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .iud_info .flex-group {
    display: block;

    .iud-info-image {
      height: 310px;
      width: 107px;
      float: right;
      margin-left: 30px;

      // NOTE: There are issues with this image rendering at the wrong width
      // in chrome and setting the min-width seems to resolve that issue.
      min-width: 107px;
    }

    .markdown-block {
      padding-bottom: 27px;
      margin: 0;

      p, ul li { padding-right: 0; }

      ul {
        padding-left: 37px;
        padding-bottom: 8px;

        li { margin-bottom: 8px;}
      }
    }
  }
}
