@import '../../../scss/mixins';

.got_questions {
  .textarea-container{ 
    position: relative;
    min-height: 150px;
    max-height: 300px;
    width: 100%;
    margin-bottom: 30px;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    @include note-textarea-and-placeholder;
    
  }

  .nav-buttons-container .nav-button { 
    width: auto;
    padding: 0 18px;
  }
}

@media only screen and (max-width: 719px) {
  .got_questions .nav-buttons-container .nav-button {
    width: 100%;
  }
}