@import '../../../scss/colors';

.nav-buttons-container {
  
  display: -webkit-flex; 
  display: flex;
  position: relative;
  margin-top: auto;
  padding-bottom: 30px;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;

  .nav-button {
    font-style: italic;
    color: white;
    background-color: $dark_blue;
    text-align: center;
    line-height: 38px;
    font-size: 1.25em;
    width: 130px;
    box-sizing: border-box;
    white-space: nowrap;
    border: 1px solid white;
    margin: 0;
  }
}

@media only screen and (max-width: 719px){
  .nav-buttons-container {
    .nav-button {
      font-size: 15px;
      width: 100px;
      line-height: 28px;
    }
  }
}
