@mixin archer {
  font-family: "Archer SSm A", "Archer SSm B";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

%silo-style {
  background-repeat: no-repeat;
  align-self: center;
}

@mixin mobile-heading-style {
  padding: 15px 0 12px ;
 
  h1 {
    font-size: 18px;
    padding: 4px 0;
    white-space: pre-wrap;
  }
}

@mixin mobile-paragraph-style {
  font-size: 16px;
  line-height: 1.5;
}

@mixin mobile-modal-style {
  margin: auto 20px;
  padding: 15px;
  width: auto;
  
  .modal-content p {
    @include mobile-paragraph-style;
  }
}

@mixin mobile-survey-squarcle-button {
  width: 21.3vw;
  height: 21.3vw;
  max-width: 80px; 
  max-height: 80px;
  margin: 0;
  padding: 0;
  min-width: 0;
  border-radius: 10px;
  white-space: pre;
  line-height: 1.3;
}

@mixin mobile-multi-part-question {
  .survey-questions-container .survey-question-container.multi .survey-categories-container {
    padding: 0;
    
    .survey-category-container{
      margin-bottom: 14px; 
      
      .survey-choice { @include mobile-survey-squarcle-button;}
    }
  }
}
$info-box-arrow-border-style: 10px solid;

@mixin shared-arrow-spec {
  $white-border-size: $info-box-arrow-border-style transparent;
  border-left: $white-border-size;
  border-right: $white-border-size;
  width: 0;
  position: relative;
  visibility: hidden;
  box-sizing: border-box;
}

$info-box-background-color: rgba(0,85,102, 0.95);

@mixin common-info-box-style {
  position: absolute;
  color: white;

  %info-box-body-style {
    $info-box-body-padding: 10px;
    background-color: $info-box-background-color;
    padding-left: $info-box-body-padding;
    padding-right: $info-box-body-padding;
  }

  .paragraph-container .paragraph p { margin-bottom: 0 }

  .info-box-up-arrow {
    @include shared-arrow-spec;
    border-bottom:20px solid $info-box-background-color;
  }

  .info-box-down-arrow {
    @include shared-arrow-spec;
    border-top: 20px solid $info-box-background-color;
  }

  .info-box-head {
    @extend %info-box-body-style;
    font-size: 0.875em;
    font-style: italic;
    line-height: 1.125em;
    padding-top: 9px;
    padding-bottom: 5px;
    
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    button {
      // background-image: url('../../../shared_images/x-icon.png');
      background-color: transparent;
      background-size: contain;
      padding: 0;
      height: 17px;
      width: 17px;
      border: none;
      color: transparent;
      font-size: 0;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
    }
  }
  .info-box-body {
    @extend %info-box-body-style;
    border-top: 1px dotted white;
    font-size: 0.6875em;
    line-height: 1.2;
    padding: 6px 10px 10px 10px;
    font-family: "Lucida Sans","Lucida Grande",'Lucida Sans Unicode','Lucida',Verdana,Arial,sans-serif;

  }
  

  &.up .info-box-up-arrow { visibility: visible}
  &.down .info-box-down-arrow { visibility: visible}

}

@mixin method-icon-actual-image-style($path-to-shared_images) {
  background-size: contain;
  background-repeat: no-repeat;

  &.sterilization {background-image: url('#{$path-to-shared_images}/shared_images/methods/sterilization@2x.jpg')}
  &.hormonalIud {background-image: url('#{$path-to-shared_images}/shared_images/methods/hormonal-iud@2x.jpg')}
  &.copperIud {background-image: url('#{$path-to-shared_images}/shared_images/methods/copper-iud@2x.jpg')}
  &.implant {background-image: url('#{$path-to-shared_images}/shared_images/methods/implant@2x.jpg')}
  &.shot {background-image: url('#{$path-to-shared_images}/shared_images/methods/shot@2x.jpg')}
  &.ring {background-image: url('#{$path-to-shared_images}/shared_images/methods/ring@2x.jpg')}
  &.patch {background-image: url('#{$path-to-shared_images}/shared_images/methods/patch@2x.jpg')}
  &.pill {background-image: url('#{$path-to-shared_images}/shared_images/methods/pill@2x.jpg')}
  &.diaphragm {background-image: url('#{$path-to-shared_images}/shared_images/methods/diaphragm@2x.jpg')}
  &.maleCondom {background-image: url('#{$path-to-shared_images}/shared_images/methods/male-condom@2x.jpg')}
  &.femaleCondom {background-image: url('#{$path-to-shared_images}/shared_images/methods/internal-condom@2x.jpg')}
  &.cervicalCap {background-image: url('#{$path-to-shared_images}/shared_images/methods/cervical-cap@2x.jpg')}
  &.fertilityAwareness {background-image: url('#{$path-to-shared_images}/shared_images/methods/fertility-awareness@2x.jpg')}
  &.sponge {background-image: url('#{$path-to-shared_images}/shared_images/methods/sponge@2x.jpg')}
  &.spermicide {background-image: url('#{$path-to-shared_images}/shared_images/methods/spermicide@2x.jpg')}
  &.withdrawal {background-image: url('#{$path-to-shared_images}/shared_images/methods/withdrawal@2x.jpg')}
  &.emergencyContraception {background-image: url('#{$path-to-shared_images}/shared_images/methods/emergency-contraception@2x.jpg')}
  &.other {background-image: url('#{$path-to-shared_images}/shared_images/methods/other@2x.jpg')}
  &.notRightNow {background-image: url('#{$path-to-shared_images}/shared_images/methods/not-right-now@2x.jpg')}  
  &.notUsingAnyMethod {
    background-image: url('#{$path-to-shared_images}/shared_images/methods/no-method@2x.jpg');
    background-position: center center;
  }
  &.progestinOnlyPill {background-image: url('/shared_images/methods/progestin_only_pill@2x.jpg')}
}
@mixin modal-green-title {
  @include archer;
  text-transform: uppercase;
  font-size: 24px;
  display: inline-block;
  color: $link_green;
  padding-left: 35px;
  background-repeat: no-repeat;
  background-size: auto 27px;
  line-height: 1;
  margin-bottom: 16px;
}

@mixin square-flexbox-menu-container{
  
  display: -webkit-flex;
  display: flex;

  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

@mixin flexbox-menu-item {
  box-sizing: border-box;
  
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

@mixin mobile-full-screen-menu-item-modal {
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-radius: 0;
  padding: 0;
  
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;

  .rainbow {
    font-size: 0;
  }

  .modal-content {
    padding: 20px 5% 30px 5%;
    
    &.first h1 { background-image: none}
    &.second h1 { background-image: none}
    &.third h1 { background-image: none}
    &.fourth h1 { background-image: none}

    h1 {   padding-left: 0; }

    p { font-size: 16px; }

    
  }

  button.close-x {
    display: none;
  }

  .nav-buttons-container {
    align-self: flex-end;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@mixin flexbox-icon-container {
  
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  
}

@mixin flexbox-icon {
  
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

@mixin mobile-row-of-3-icon-container {
  width: 100%;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;

  .icon-button {
    width: 25vw;
    height: calc(25vw + 30px);

    p { font-size: 14px; }

    &:nth-child(1n) { margin: 0 3.5vw 4vw; }
    &:nth-child(3n + 1) {  margin-left: 0; }
    &:nth-child(3n) {  margin-right: 0; }
  }
}

@mixin small-green-icon {
  background-image: url('../../mybc/SideEffectsPerMethod/side-effects-by-method-sprite@2x.png');
  display: inline-block;
  position: relative;
  top: 28px * 0.125;
  height: 28px;
  width: 6%;
  margin-right: 2%;
  background-size: 60vw;
  background-position-x: 0.1vw;

  &.sterilization {background-position-y: -5.75vh;}
  &.hormonalIud {background-position-y: -9.5vh;}
  &.copperIud {background-position-y: -13.25vh;}
  &.implant {background-position-y: -17vh;}
  &.shot {background-position-y: -20.75vh;}
  &.ring {background-position-y: -28.25vh;}
  &.patch {background-position-y: -24.5vh;}
  &.pill {background-position-y: -32vh;}
  &.diaphragm {background-position-y: -35.75vh;}
  &.maleCondom {background-position-y: -39.5vh;}
  &.femaleCondom {background-position-y: -43.25vh;}
}

@mixin long-h1-heading {
  h1{ 
    line-height: 1.575;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    display: inline;
  }
  @media only screen and (min-width: 640px){ h1{ font-size: 25px}}
  @media only screen and (max-width: 639px){ h1{ font-size: calc(3.87vw + 0.1px)}}
  @media only screen and (max-width: 465px){ h1{ font-size: 18px}}
}

@mixin note-textarea-and-placeholder { 
  .placeholder {
    position: absolute;
    color: #afafaf;
    font-family: Georgia;
    padding: 10px;
    font-style: italic;
    max-height: calc(100% - 20px);
    overflow: hidden;

    .markdown-block {
      padding: 0 ;

      p {
        font-size: 16px;
        line-height: 20px;
        color: #757575;
        margin-bottom: 10px;
        display: none;
      }
    }

    p:nth-child(1), p:nth-child(2), p:nth-child(3) {
      display: block;
    }
  }

  textarea {
    height: 100%;
    width: 100%;
    border: 1px solid #858585;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
    background: transparent;
    font-family: Georgia;
    position: absolute;
    top: 0;
    box-shadow: none;
    border-radius: 5px;
    -webkit-appearance: none;

    &::placeholder { color: transparent; }
  }

  @media only screen and (min-width: 720px) {
    .placeholder .markdown-block p { display: block; }
  } 
}