@import '../../../scss/mixins';
@import '../../../scss/colors';

.side_effects_per_method {
  position: relative;

  .icon-container {
    @include flexbox-icon-container;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 20px;
    max-width: 600px;

    .icon-button {
      @include flexbox-icon;
      font-size: 16px;
      margin: 10px 15px 25px 15px;
      width: 115px;
      height: 152px;
      background-color: white;
      box-sizing: border-box;
      position: relative;
      @include method-icon-actual-image-style('../../..');

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }

    }
  }

  .overlay {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    .modal {
      margin: auto;
      box-sizing: border-box;
      padding: 20px 20px 0 20px;
      align-self: center;

      button {
        width: 30px;
        height: 30px;
        margin-top: 0;
        margin-left: 0;
        padding: 0;
      }

      .modal-content {
        display: inline-block;

        .modal-heading-container {
          width: 100%;
          margin-bottom: 15px;

          $h1-height: 1.6875em;

          h1 { 
            @include modal-green-title;  
            padding: 0;
            margin: 0;
          }

        }

        .stuff-container {
          // margin-bottom: 12%;
          // margin-left: 0.5%;
          margin-bottom: 20px;

          h2 {
            // font-style: italic;
            font-size: 1.25em;
            margin-bottom: 3.75%;
            @include archer;
            text-transform: capitalize;
          }

          $li-height: 1.1em;

          li {
            list-style: none;
            font-size: $li-height;
            width: 100%;
            margin-bottom: 10px;
            
            display: -webkit-flex; 
            display: flex;

            $bullet-width: 6%;

            .modal-list-bullet {
              height: $li-height * 1.5;
              width: $bullet-width;
              margin-right: 8px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: left;
            }

            .text {
              line-height: 1.325;
              align-self: flex-end;
              width: 100% - $bullet-width;
              font-style: italic;
            }
          }

          &.modal-good-stuff-container {
            .modal-list-bullet { background-image: url('./smiley_face_no_shadow.svg');}
          }

          &.modal-annoying-stuff-container {
            .modal-list-bullet { background-image: url('./sad_face_no_shadow.svg');}
          }

          &.modal-no-worry-stuff-container {
            .modal-list-bullet { background-image: url('./neutral_face_no_shadow.svg');}
          }
        }
      }
    }
  }

  .nav-buttons-container {
    justify-self: flex-end;
  }
}

@media only screen and (max-width: 620px) {
  .side_effects_per_method {
    padding: 0px 20px;
    margin-left: 0;
    margin-right: 0;
      
    .heading-container {
      @include mobile-heading-style;
    }
    
    .markdown-block p {
      font-size: 16px;
      line-height: 1.5;
    }

    .icon-container { @include mobile-row-of-3-icon-container;}

    .overlay {
      .modal {
        width: calc(100vw - 40px);
        margin-left: 20px;
        margin-right: 20px;

        .modal-content {
          .modal-heading-container h1 {font-size: 20px; }

          .stuff-container {
          
            h2 { font-size: 18px; }
            
            li .text { font-size: 14px}
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 980px) {
  .side_effects_per_method .icon-container {  max-width: none; }
}
