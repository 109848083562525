@import '../../../scss/dimensions';
@import '../../../scss/colors';

.how_often {
  .mobile { display: none }

  .markdown-block p:last-of-type { margin-bottom: 0; }

  .bc-bars-and-brackets-container {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-top: 19px;
    margin-bottom: 35px;
    display: -webkit-flex; 
    
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    height: 494px;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;

    .brackets-container {
      display: flex;
      display: -webkit-flex; 
      
      -webkit-justify-content: space-between;
      justify-content: space-between;
      font-style: italic;
      font-size: 12px;
      line-height: 1.4;
      width: 100%;
    }

    .bc-bar-items-container {
      margin-top: 6px;

      .bc-bar-item {
        &.femaleCondom { background-image: url('../../../shared_images/illustrated_method_icons/female-condom-grey-OFF.png');
          border-left: none;
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/female-condom-green-ON.png');}
        }
        &.maleCondom { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-green-ON.png');}
        }
        &.diaphragm { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-green-ON.png');}
        }
        &.pill { background-image: url('../../../shared_images/illustrated_method_icons/pill-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/pill-green-ON.png');}
        }
        &.patch { background-image: url('../../../shared_images/illustrated_method_icons/patch-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/patch-green-ON.png');}
        }
        &.ring { background-image: url('../../../shared_images/illustrated_method_icons/ring-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/ring-green-ON.png');}
        }
        &.shot { background-image: url('../../../shared_images/illustrated_method_icons/shot-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/shot-green-ON.png');}
        }
        &.implant { background-image: url('../../../shared_images/illustrated_method_icons/implant-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/implant-green-ON.png');}
        }
        &.iud { background-image: url('../../../shared_images/illustrated_method_icons/iud-grey-OFF.png');
          &.selected { background-image: url('../../../shared_images/illustrated_method_icons/iud-green-ON.png');}
        }
        &.sterilization { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-grey-OFF.png');
        &.selected { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-green-ON.png');}
        }
      }
    }
    .bc-result-container{
      width: $method-box-width * 10;
      padding: 5px 10px;
      min-height: 30px;

      p {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  %year-text-background-settings {
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .calendar {
    margin-top: auto;
    height: 354px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 450px auto;

    .infinity-arrow-container {
      &.right {
        width: 43px;
        height: 104px;
        
        .infinity-arrow {
          border-top: 10px solid white;
          border-bottom: 10px solid white;
          border-left: 20px solid $medium_gray;
          bottom: -15px;
          right: -1px;
        }
      }

      &.down {
        display: -webkit-flex; 
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        
        .infinity-arrow {
          border-left: 11px solid white;
          border-right: 11px solid white;
          border-top: 15px solid $medium_gray;
          height: 15px;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }
        
        .infinity-symbol {
          background-image: url('./images/infinity.png');
          width: 75px; 
          height: 30px;
          background-color: white;
          background-size: contain;
          background-position: center bottom;
          background-repeat: no-repeat;
          position: absolute;
          bottom:0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
        }
      }

      .infinity-arrow { position: absolute }
    }

    .year-box-container {
      margin-top: 7px;
      
      display: flex;
      display: -webkit-flex; 
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;

      .year-box {
        height: 104px;
        text-align: center;
        
        display: flex;
        display: -webkit-flex; 
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        position: relative;
        top: 15px;
        
        p {
          font-family: "Archer SSm A", "Archer SSm B";
          font-size: 14px;
          font-weight: 700;
          color: $dark_medium_gray;
        }

        &.peak {
          .year-text-container {
            box-sizing: border-box;
            background-color: white;
            width: 100%;
            text-align: center;
            
            display: flex;
            display: -webkit-flex; 
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: center;
            
            .year-text {
              padding: 0 3px;
              width: 40px;
              box-sizing: border-box;
              height: 60px;
              
              p {  color: $dark_gray; }
            }
          }

          .down-arrow {
            border-left: 10px solid white;
            border-right: 10px solid white;
            border-top: 20px solid $link_green;
          }
        }

        .pole {
          background-color: $medium_gray;
          
          &.long-pole { 
            width: 4px;
            height: 20px;
            margin-top: 5px;
          }
        }
      }
    }

    .main-bar { 
      width: 100%;
      height: 10px;
      background-color: $medium_gray;
    }

    .mobile {
      .year-box-container {
        -webkit-flex-direction: column;
        flex-direction: column;

        .year-box.peak {
          height: auto; 
          &.left { align-self: flex-start}
          &.right { align-self: flex-end}
        }
      }
    }

    &.femaleCondom { background-image: url('./images/cal-female-condom.jpg')}
    &.maleCondom { background-image: url('./images/cal-male-condom.jpg')}
    &.diaphragm { background-image: url('./images/cal-diaphragm.jpg')}
    &.pill { background-image: url('./images/cal-pill.jpg')}
    &.patch { background-image: url('./images/cal-patch.jpg')}
    &.ring { background-image: url('./images/cal-ring.jpg')}
    &.shot { 
      .year-box-container .year-box.peak .year-text{
        background-image: url('../../../shared_images/illustrated_method_icons/shot.jpg');
        @extend %year-text-background-settings;
      }
    }
    &.implant { 
      width: 437px; 

      .year-box-container .year-box.peak .year-text{
        background-image: url('../../../shared_images/illustrated_method_icons/implant.jpg');
        @extend %year-text-background-settings;
      }
    }
    &.iud { 
      -webkit-flex-shrink: 1;
      flex-shrink: 1;
      .year-box-container .year-box.peak .year-text{
        background-image: url('../../../shared_images/illustrated_method_icons/iud.jpg');
        @extend %year-text-background-settings;
      }
    }
    &.sterilization {  
      .year-box-container .year-box.peak .year-text{
        background-image: url('../../../shared_images/illustrated_method_icons/sterilization.jpg');
        @extend %year-text-background-settings;
      }
    }
  }

  &.es {

    .bc-bars-and-brackets-container { margin-top: 5%; }
  }
}
  
@media only screen and (min-width: 835px){
  .how_often {
    .bc-bars-and-brackets-container {
      height: 506px;

      .bc-bar-items-container {
        margin-top: 8px;

        .bc-bar-item {
          height: $method-box-width-large;
          width: $method-box-width-large;
        } 
      }

      .bc-result-container {
        width: $method-box-width-large * 10;
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .how_often{
    .regular { display: none }
    .mobile { display: block }

    .bc-bars-and-brackets-mobile-container {
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      margin-top: 15px;
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;
      -webkit-justify-content: space-between;
      justify-content: space-between;

      .bracket-and-bar-group {
        margin-bottom: 30px;
        &.bracket-and-bar-group-0 { width: 80%;} 
        &.bracket-and-bar-group-1 {  width: 100%; } 
        &.bracket-and-bar-group-2 {  width: 80%; } 

        .brackets { display: none;}
      
        .bc-bar-items-container {
          -webkit-justify-content: space-around;
          justify-content: space-around;

          .bc-bar-item {
            width: $mobile-large-icon;
            height: $mobile-large-icon;
            border: none;
            &.femaleCondom { background-image: url('../../../shared_images/illustrated_method_icons/female-condom-orange-OFF.png');}
            &.maleCondom { background-image: url('../../../shared_images/illustrated_method_icons/male-condom-orange-OFF.png');}
            &.diaphragm { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm-orange-OFF.png');}
            &.pill { background-image: url('../../../shared_images/illustrated_method_icons/pill-blue-OFF.png');}
            &.patch { background-image: url('../../../shared_images/illustrated_method_icons/patch-blue-OFF.png');}
            &.ring { background-image: url('../../../shared_images/illustrated_method_icons/ring-blue-OFF.png');}
            &.shot { background-image: url('../../../shared_images/illustrated_method_icons/shot-blue-OFF.png');}
            &.implant { background-image: url('../../../shared_images/illustrated_method_icons/implant-green-OFF.png');}
            &.iud { background-image: url('../../../shared_images/illustrated_method_icons/iud-green-OFF.png');}
            &.sterilization { background-image: url('../../../shared_images/illustrated_method_icons/sterilization-green-OFF.png');}
          }
        }
      }
    }

    .mobile-graphics-container .overlay .modal {
      height: auto;
      width: calc(100vw - 40px);

      .heading-container h1{ color: $link_green}

      .calendar {
        width: 100%;
        height: calc((100vw - 60px) * 0.78);
        max-height: 300px;
        background-size: contain;
        
        display: -webkit-flex; 
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        &.shot, &.implant, &.iud, &.sterilization { 
          
          display: -webkit-flex; 
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          margin-top: 20px;
          height: auto;
          
          .mobile { 
            height: 236px;
            width: 148px;
            margin-top: 5px;
            position: relative;

            .year-box-container {
              margin: 0;
              height: 100%;
    
              .year-box.peak {
                top: auto;
    
                &.left .left-right-arrow {
                  border-left: 20px solid $link_green;
                  left: 40px;
                }
                &.right .left-right-arrow {
                  border-right: 20px solid $link_green;
                  right: 40px;
                }
                
                .left-right-arrow {
                  border-top: 10px solid white;
                  border-bottom: 10px solid white;
                  position: absolute;
                  top: 26px;
                }

                .year-text-container .year-text { 
                  height: 56px;

                  p { line-height: 1 };
                }
              }
            }

            .main-bar {
              background-color: $medium_gray;
              // background-color: rgb(91, 91, 245);
              height: 194px;
              width: 10px;
              position: absolute;
              top: 29px;
              transform: translateX(-50%);
              left: 50%;
            }

            .pole-container {
              position: absolute;
              // background-color: lightblue;
              box-sizing: border-box;
              height: 100%;
              width: 100%;
              bottom: 0;
              
              display: -webkit-flex; 
              display: flex;
              -webkit-flex-direction: column;
              flex-direction: column;
              -webkit-justify-content: space-between;
              justify-content: space-between;
              -webkit-align-items: center;
              -webkit-box-align: center;
              align-items: center;
              padding-top: 34px;
              padding-bottom: 18px;
              
              .pole {
                background-color: $medium_gray;
                // background-color: pink;
                width: 20px;
                height: 4px;
              }
            }
          }
        }

        &.sterilization .mobile .pole-container { padding-bottom: 40px; }
      }

      .reserved-space { 
        height: 10px; 
        width: 100%;
      }
    }

    .es .heading-container h1{font-size: 18px;}
  }
}