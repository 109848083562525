@charset "UTF-8";
/********  Colors  ********/
.summary {
  position: relative;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }
  .summary .print-button.regular {
    background-color: transparent;
    width: 194px;
    background-image: url("./print-ribbon.png");
    padding-left: 40px;
    padding-bottom: 20px; }
  .summary .print-button.mobile {
    background-color: #009cba;
    width: 100%;
    font-size: 20px;
    line-height: 1;
    color: white;
    display: none;
    background-image: none;
    position: initial;
    height: 40px;
    margin-bottom: 20px; }
    .summary .print-button.mobile p, .summary .print-button.mobile img {
      display: inline; }
    .summary .print-button.mobile p {
      position: relative;
      bottom: 5px;
      left: 10px; }
  .summary .bc-placeholder {
    margin-bottom: 20px; }
  .summary .modal {
    padding: 20px; }
  .summary .summary-heading {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0; }
    .summary .summary-heading .user-id {
      font-size: 24px;
      font-style: italic;
      padding: 24px 0 20px;
      margin-left: 5px;
      line-height: 1.3;
      display: none;
      white-space: pre; }
  .summary .hidden {
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: block; }
  .summary .description {
    font-size: 13px;
    line-height: 1.2;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 20px; }
  .summary .summary-container {
    max-width: 691px;
    margin: 0px auto; }
  .summary .item {
    border: 1px solid #006477;
    margin-bottom: 20px;
    page-break-inside: avoid; }
    .summary .item h2 {
      font-size: 13px;
      font-style: italic;
      font-weight: normal;
      line-height: 1.5;
      color: #ffffff;
      background: #006477;
      margin: 0;
      padding: 3px 10px; }
    .summary .item ul {
      page-break-inside: avoid; }
    .summary .item .empty {
      padding: 10px 10px 10px 0;
      margin: 0;
      font-size: 13px;
      line-height: 19px;
      font-style: italic;
      color: #404040; }
  .summary .item.topics .empty {
    padding: 10px; }
  .summary .item.empty-table {
    margin-bottom: 10px; }
    .summary .item.empty-table th.screen-reader-only {
      font-size: 1px;
      color: transparent;
      background: none;
      border: 0; }
    .summary .item.empty-table td, .summary .item.empty-table td:first-child {
      background: none;
      border: none;
      color: #404040;
      padding-left: 0; }
  .summary .item.check-table td img {
    vertical-align: middle; }
  .summary .item.questions {
    border-bottom: none; }
    .summary .item.questions ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      width: 100%; }
      .summary .item.questions ul li {
        border-bottom: 1px solid #006477;
        display: table-row;
        border-collapse: collapse; }
        .summary .item.questions ul li .question, .summary .item.questions ul li .answer {
          width: 50%;
          font-size: 13px;
          line-height: 23px;
          padding: 5px 10px;
          border-bottom: 1px solid #006477;
          vertical-align: middle; }
          .summary .item.questions ul li .question img, .summary .item.questions ul li .answer img {
            vertical-align: middle; }
        .summary .item.questions ul li .question {
          font-style: italic;
          font-size: 13px;
          color: #006477;
          font-weight: normal;
          border-right: 1px solid #006477;
          display: table-cell;
          background: #d1edf2; }
          .summary .item.questions ul li .question em {
            text-decoration: underline; }
        .summary .item.questions ul li .answer {
          font-size: 13px;
          line-height: 1.2;
          background: #fff;
          display: table-cell; }
      .summary .item.questions ul li:last-child {
        border-bottom: none; }
  .summary .item.topics {
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }
    .summary .item.topics h2 {
      width: 100%; }
    .summary .item.topics ul {
      font-size: 13px;
      line-height: 1.2;
      width: 50%;
      margin-top: 15px;
      margin-bottom: 15px;
      box-sizing: border-box; }
      .summary .item.topics ul li {
        margin-left: 15px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-bottom: 8px;
        display: -webkit-flex;
        display: flex; }
        .summary .item.topics ul li:before {
          content: '□';
          content: '';
          width: 8px;
          height: 15px;
          background-image: url("./white_square_u25A1_icon_256x256.png");
          background-size: contain;
          background-position: center top 4px;
          background-repeat: no-repeat; }
        .summary .item.topics ul li p {
          padding-left: 5px; }
  .summary .item.when-pregnant p {
    font-size: 13px;
    line-height: 1.2;
    padding: 10px; }
  .summary .item.notes .note {
    padding: 14px 14px 7px 14px;
    font-size: 13px;
    line-height: 1.2; }
    .summary .item.notes .note p {
      margin-top: 0px;
      margin-bottom: 9px; }
      .summary .item.notes .note p:before {
        content: '»';
        margin-right: 6px; }
  .summary .item.notes .note.empty {
    font-size: 13px;
    line-height: 19px;
    font-style: italic;
    color: #808080; }
  .summary .methods {
    page-break-inside: avoid; }
    .summary .methods h2 {
      font-style: italic;
      font-weight: bold;
      color: #006477;
      font-size: 14px; }
    .summary .methods ul {
      list-style: none;
      padding: 20px 0px 0px;
      margin: 0; }
    .summary .methods li {
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      margin-right: 32px;
      margin-bottom: 20px;
      padding-top: 85px;
      display: inline-block; }
      .summary .methods li.sterilization {
        background-image: url("../../../shared_images/methods/sterilization@2x.jpg"); }
      .summary .methods li.hormonalIud {
        background-image: url("../../../shared_images/methods/hormonal-iud@2x.jpg"); }
      .summary .methods li.copperIud {
        background-image: url("../../../shared_images/methods/copper-iud@2x.jpg"); }
      .summary .methods li.implant {
        background-image: url("../../../shared_images/methods/implant@2x.jpg"); }
      .summary .methods li.shot {
        background-image: url("../../../shared_images/methods/shot@2x.jpg"); }
      .summary .methods li.ring {
        background-image: url("../../../shared_images/methods/ring@2x.jpg"); }
      .summary .methods li.patch {
        background-image: url("../../../shared_images/methods/patch@2x.jpg"); }
      .summary .methods li.pill {
        background-image: url("../../../shared_images/methods/pill@2x.jpg"); }
      .summary .methods li.diaphragm {
        background-image: url("../../../shared_images/methods/diaphragm@2x.jpg"); }
      .summary .methods li.maleCondom {
        background-image: url("../../../shared_images/methods/male-condom@2x.jpg"); }
      .summary .methods li.femaleCondom {
        background-image: url("../../../shared_images/methods/internal-condom@2x.jpg"); }
      .summary .methods li.cervicalCap {
        background-image: url("../../../shared_images/methods/cervical-cap@2x.jpg"); }
      .summary .methods li.fertilityAwareness {
        background-image: url("../../../shared_images/methods/fertility-awareness@2x.jpg"); }
      .summary .methods li.sponge {
        background-image: url("../../../shared_images/methods/sponge@2x.jpg"); }
      .summary .methods li.spermicide {
        background-image: url("../../../shared_images/methods/spermicide@2x.jpg"); }
      .summary .methods li.withdrawal {
        background-image: url("../../../shared_images/methods/withdrawal@2x.jpg"); }
      .summary .methods li.emergencyContraception {
        background-image: url("../../../shared_images/methods/emergency-contraception@2x.jpg"); }
      .summary .methods li.other {
        background-image: url("../../../shared_images/methods/other@2x.jpg"); }
      .summary .methods li.notRightNow {
        background-image: url("../../../shared_images/methods/not-right-now@2x.jpg"); }
      .summary .methods li.notUsingAnyMethod {
        background-image: url("../../../shared_images/methods/no-method@2x.jpg");
        background-position: center center; }
      .summary .methods li.progestinOnlyPill {
        background-image: url("/shared_images/methods/progestin_only_pill@2x.jpg"); }
      .summary .methods li:nth-child(6n) {
        margin-right: 0; }
      .summary .methods li:nth-child(6n + 1) {
        clear: both; }
    .summary .methods .empty {
      padding: 10px 10px 10px 0px;
      margin: 0;
      font-size: 13px;
      line-height: 19px;
      font-style: italic;
      color: #404040;
      margin-bottom: 10px; }
  .summary .flex-container {
    display: -webkit-flex;
    display: flex; }
    .summary .flex-container .item.red-flags, .summary .flex-container .item.important {
      box-sizing: border-box;
      width: calc(50% - 15px);
      clear: none; }
  .summary .item.important ul li .question {
    width: 60%;
    font-style: italic;
    font-size: 13px;
    color: #006477;
    font-weight: normal; }
  .summary .item.important ul li .answer {
    width: 41%;
    text-align: center;
    line-height: 17px;
    min-height: 27px; }
    .summary .item.important ul li .answer img {
      display: block;
      margin: 1px auto; }
  .summary .item.red-flags {
    margin-left: 30px; }
    .summary .item.red-flags .body {
      padding: 10px;
      font-size: 13px;
      line-height: 1.2;
      min-height: 63px; }
  .summary .item.side-effects ul li .question {
    width: 30%; }
  .summary .item.side-effects ul li .answer {
    width: 70%; }
  .summary table.item {
    width: 100%;
    border-spacing: 0;
    font-style: italic;
    font-size: 13px;
    border-collapse: collapse;
    border: none;
    table-layout: fixed; }
    .summary table.item caption {
      font-size: 14px;
      line-height: 14px;
      color: #006477;
      margin-bottom: 5px;
      text-align: left;
      font-weight: bold; }
    .summary table.item thead th {
      font-size: 13px;
      font-style: italic;
      font-weight: normal;
      line-height: 1.5;
      color: #ffffff;
      line-height: 1.3;
      background: #006477;
      color: #fff;
      padding: 5px 10px;
      text-align: center;
      border: 1px solid #006477; }
    .summary table.item td, .summary table.item tbody th {
      text-align: center;
      border: 1px solid #006477;
      padding: 5px 10px;
      width: 23.66%;
      line-height: 13px;
      color: #006477;
      vertical-align: middle; }
      .summary table.item td:first-child, .summary table.item tbody th:first-child {
        text-align: left;
        background: #d1edf2;
        width: 29%;
        font-style: italic;
        font-size: 13px;
        color: #006477;
        font-weight: normal; }
    .summary table.item.columns-4 td {
      width: 17.5%; }
      .summary table.item.columns-4 td:first-child {
        width: 29%; }
  .summary .summary-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    padding-bottom: 30px;
    margin-top: auto; }
    .summary .summary-button-container #summary-main-menu, .summary .summary-button-container #summary-restart {
      margin-right: 10px; }
    .summary .summary-button-container .summary-button {
      padding: 8px;
      width: 200px;
      box-sizing: border-box;
      color: white;
      font-style: italic;
      font-size: 24px;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      justify-content: center; }
      .summary .summary-button-container .summary-button.restart {
        background-color: #009cba; }
      .summary .summary-button-container .summary-button.main-menu {
        background-color: #669900; }
      .summary .summary-button-container .summary-button.email {
        background-color: #005566;
        margin-right: 0;
        margin-left: auto;
        cursor: pointer; }
      .summary .summary-button-container .summary-button img {
        margin-right: 10px;
        height: 27px; }

@media only screen and (max-width: 719px) {
  .summary .print-button.regular {
    display: none; }
  .summary .print-button.mobile {
    display: block; }
  .summary .summary-heading .heading-container h1, .summary .summary-heading .user-id {
    font-size: 18px; }
  .summary .summary-heading .heading-container {
    padding: 15px 0 12px; }
  .summary .summary-heading .user-id {
    padding: 19px 0 16px; }
  .summary .description {
    font-size: 16px;
    line-height: 22px; }
  .summary .summary-container {
    width: 90vw; }
  .summary table.item th {
    font-size: 12px; }
  .summary table.item td:first-child {
    font-size: 12px; }
  .summary .item.topics {
    padding-bottom: 6px; }
    .summary .item.topics h2 {
      margin-bottom: 6px; }
    .summary .item.topics ul {
      margin: 0;
      width: 100%; }
      .summary .item.topics ul li {
        margin: 2px 10px; }
  .summary .flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column; }
    .summary .flex-container .item.important.questions,
    .summary .flex-container .item.red-flags {
      width: 100%;
      margin-left: 0; }
      .summary .flex-container .item.important.questions .body,
      .summary .flex-container .item.red-flags .body {
        min-height: 0; }
  .summary .summary-button-container {
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-bottom: 0; }
    .summary .summary-button-container .summary-button, .summary .summary-button-container #summary-main-menu, .summary .summary-button-container #summary-restart {
      width: 90vw;
      margin: 0 auto 20px; }
    .summary .summary-button-container .summary-button.email {
      margin-right: auto; }
  .summary .modal .modal-content input::placeholder, .summary .modal .modal-content p {
    font-size: 16px;
    line-height: 1.5; }
  .summary.es table.check-table td, .summary.es table.check-table th {
    padding: 5px; } }

@media only screen and (max-width: 500px) {
  .summary table.check-table.item tbody td, .summary table.check-table.item thead th, .summary table.check-table.item tbody th {
    padding: 5px;
    font-size: 12px; } }

@media only screen and (max-width: 374px) {
  .summary table.check-table.item tbody td, .summary table.check-table.item thead th, .summary table.check-table.item tbody th {
    padding: 5px 1px;
    font-size: 11px; } }

@media print {
  .summary.background-image {
    min-height: auto;
    padding: 0;
    max-width: 1160px;
    display: block; }
    .summary.background-image * {
      line-height: 1 !important; }
    .summary.background-image .summary-heading .user-id {
      display: block; }
    .summary.background-image .summary-container {
      page-break-after: avoid; }
      .summary.background-image .summary-container .item {
        margin-bottom: 15px; }
        .summary.background-image .summary-container .item:last-of-type {
          page-break-after: avoid; }
    .summary.background-image .summary-button-container {
      display: none; }
    .summary.background-image .print-button {
      display: none; }
    .summary.background-image.firefox .item {
      width: 100%; }
      .summary.background-image.firefox .item th, .summary.background-image.firefox .item h2, .summary.background-image.firefox .item ul li div {
        background-color: white; }
      .summary.background-image.firefox .item h2 {
        border-bottom: 2px solid #005566; } }
