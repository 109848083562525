@import '../../../scss/colors';
@import '../../../scss/mixins';

.health_before_pregnancy {
  .markdown-block p em { text-decoration: underline }

  .visually-hidden {
    display: none;
  }

  .items-container {
    
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0px auto 10px auto;
    max-width: 670px;

    .item {
      margin-bottom: 20px;
      margin-right: 40px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      button.item-button {
        width: 125px;
        // margin-right: calc((95vw - 590px) / 3);
        background: #fff;
        font-size: 13px;
        line-height: 1.5;
        position: relative;
      }

      .image {
        display: block;
        width: 125px;
        height: 125px;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid rgb(191, 191, 191);
        box-sizing: border-box;
        margin-bottom: 5px;
      }

      .visited-overlay-image {
        display: block;
        position: absolute;
        top: 0;
        width: 125px;
        height: 125px;
        background: url('./check@2x.png');
        background-size: contain;
      }

      &.healthy_lifestyle .image {
        background-image: url('./healthy-lifestyle@2x.png');
      }
      &.medical_conditions .image {
        background-image: url('./medical-conditions@2x.png');
      }
      &.mental_health .image {
        background-image: url('./mental-health@2x.png');
      }
      &.medications .image {
        background-image: url('./medications@2x.png');
      }
      &.relationships .image {
        background-image: url('./relationships@2x.png');
      }
      &.healthy_weight .image {
        background-image: url('./healthy-weight@2x.png');
      }
      &.infections .image {
        background-image: url('./infections@2x.png');
      }
      &.spacing_between_babies .image {
        background-image: url('./birth-spacing@2x.png');
      }
    }
  }

  .overlay {
    .modal {
      padding: 14px 20px 20px 20px;
      box-sizing: border-box;
      width: 650px;
      color: #404040;
  
      h1 {
        @include archer;
        font-size: 28px;
        margin: 6px 0 20px;
        color: $link_green;
  
        span {
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: 1px;
          width: 24px;
          height: 24px;
          display: block;
          border: 1px solid #bfbfbf;
          margin-right: 8px;
          float: left;
        }
      }
  
      .topic-container {
        margin-bottom: 20px;
      }
  
      p {
        margin-bottom: 20px;
        font-family: Georgia;
        font-size: 20px;
        line-height: 1.3;
      }

      ul { 
        list-style-type: disc;
        font-size: 20px;
        line-height: 1.3;
        padding-left: 31px;

        li { margin-bottom: 20px; }
      }
    }
  
    &#healthy_lifestyle .modal h1 span {
      background-image: url('./healthy-lifestyle@2x.png');
    }
    &#medical_conditions .modal h1 span {
      background-image: url('./medical-conditions@2x.png');
    }
    &#mental_health .modal h1 span {
      background-image: url('./mental-health@2x.png');
    }
    &#medications .modal h1 span {
      background-image: url('./medications@2x.png');
    }
    &#relationships .modal h1 span {
      background-image: url('./relationships@2x.png');
    }
    &#healthy_weight .modal h1 span {
      background-image: url('./healthy-weight@2x.png');
    }
    &#infections .modal h1 span {
      background-image: url('./infections@2x.png');
    }
    &#spacing_between_babies .modal h1 span {
      background-image: url('./birth-spacing@2x.png');
    }
  }
}

@media screen and (min-width: 1000px) {
  .health_before_pregnancy .overlay .modal { width: 885px;}
}

@media screen and (max-width: 719px) {
  .health_before_pregnancy {
    .heading-container { padding-bottom: 10px; }
    
    .markdown-block p {
      font-size: 16px;
      line-height: 24px;
    }
    
    .items-container {
      // max-width: 400px;

      .item {
        &:nth-child(2n) {
          margin: 0px 0px 20px 0px;
        }
        &:nth-child(2n+1) {
          margin: 0px 20px 20px 0px;
          margin-right: calc(90vw - 250px - 40px);
        }

        button.item-button {
          width: 125px;
          // margin-right: calc((95vw - 590px) / 3);
          background: #fff;
          font-size: 13px;
          line-height: 1.5;
          position: relative;
        }
      }
    }


    .overlay .modal {
      width: 100%;
      height: 100%;
      overflow: scroll;
      border-radius: 0;
      padding: 0;
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;

      .rainbow {
        font-size: 0;
      }

      .modal-content {
        padding: 20px 5% 30px 5%;

        h1 {
          font-size: 20px;
          line-height: 24px;
          padding-left: 0;
          color: $dark_blue;

          span { display: none }
          // background: none !important;
        }
      }

      p, ul li {
        font-size: 16px;
        line-height: 24px;
      }

      button.close-x {
        display: none;
      }

      .nav-buttons-container {
        align-self: flex-end;
        -webkit-flex-direction: row;
        flex-direction: row;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}

@media screen and (max-width: 340px) {
 .health_before_pregnancy {
    .items-container {
      .item {
        &:nth-child(2n+1) {
          margin-right: calc(90vw - 250px - 0px);
        }
      }
    }
  }
}
