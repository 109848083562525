.mbc-footer {
  box-sizing: border-box;
  width: 100vw;
  height: 60px;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: #404040;
  padding: 0 5vw;
  justify-self: flex-end;

  img { margin-right: 20px;}

  .ucsf, .ptbi, .pcrhp  { height: 65%;}

  .user-id { 
    color: white;
    flex-grow: 1;
    text-align: right;
    font-size: 20px;
    font-style: italic;
  }
}

@media only screen and (max-width: 719px){
  .mbc-footer {
      height: 15vw;
    
     .user-id { font-size: 16px; }
  }
}

@media only screen and (min-width: 835px){
  .mbc-footer {
      height: 70px;
  }
}