@import '../../../scss/colors';

.topic-container {
  position: relative;

  div.topic-button {
    display: block;
    border: 1px solid #4a4a4a;
    padding: 15px 15px 15px 50px;
    font-family: Georgia;
    font-weight: 200;
    font-size: 17px;
    line-height: 21px;
    
    max-width: 420px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-image: url('./plus-icon.svg');

    em {
      font-size: 18
    }
  }

  &.selected div.topic-button {
    background-color: $link_green;
    background-image: url('./check-icon.svg');
    border: 1px solid $link_green;
    color: #ffffff;
  }
}

@media only screen and (max-width: 680px) {
  .topic-container {
    button.topic-button {
      max-width: 335px;
    }
  }
}

@media only screen and (max-width: 374px) {
  .topic-container {
    button.topic-button {
      width: auto;
    }
  }
}
